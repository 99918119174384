@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700&display=swap');
.font-roboto{font-family: 'Roboto', sans-serif;}

body {
    height: 100%;
    margin: 0;
    
    
}
.body-view-report-sm{
    color:#ffffff;
}
.container-body-report{
    box-shadow: none;
    height: 100%;
}

#header-top{
    background: #1D1D1D;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
}

.container-MB{
    display: grid;
    grid-template-columns: repeat(12, 1fr)!important;
    grid-auto-rows: minmax(auto, auto);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
}
.container-MB > div {
    font-family: 'Roboto', sans-serif !important;
}
.grid-item-text-logo{
    grid-column-start:2;
    grid-column-end:12;
    margin-block: auto;
    background: linear-gradient(180deg, #F4E4BA 0%, #866328 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}
.grid-timeline{
    grid-column-start:1;
    grid-column-end:12;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
}

#timeline-body{
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
}
.progress-now{
    grid-column-start:1;
    grid-column-end:7;
    border-bottom: 2px solid #C49E17;
}
.progress-now label{
    color: #C49E17;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.progress-status1{
    grid-column-start:1;
    grid-column-end:7;
}

.checkboxTimeline{
    grid-column-start:1;
    grid-column-end:9;
}
.checkboxTimelineButton{
    grid-column-start:9;
    grid-column-end:13;
}

.checkboxTimeline label{
    position: relative;
    top: -10px;
    padding-left: 10px;
    font-family: 'Roboto', sans-serif;
    /* font-style: normal; */
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
.buttonTimelineProses{
    background: #C49E17;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-align: center;
    padding: 8px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
}
.buttonTimelineSukses{
    background: #459D25;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-align: center;
    padding: 8px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
}
.checkboxTimelineBig{
    grid-column-start:1;
    grid-column-end:13;
}
.checkboxTimelineBigP{
    grid-column-start:1;
    grid-column-end:13;
    font-family: 'Roboto', sans-serif;
}

.checkboxTimelineBigP button{
    background-color: transparent;
    border: none;



    position: relative;
  background-color: transparent;
  border: none;
  /* font-size: 28px; */
  color: #FFFFFF;
  padding: 0px 20px 20px 20px;
  /* width: 200px; */
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}
.checkboxTimelineBigP button:after{
    content: "";
    border-radius: 10px;
    background: #FFFFFF;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}
.checkboxTimelineBigP button:active:after{
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}


.checkboxTimelineBigP button span:nth-child(1){
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    font-family: 'Roboto', sans-serif;
}
.checkboxTimelineBigP button span:nth-child(2){
    position: relative;
    top:10px;
    left:10px;
}
.checkboxTimelineBigP p:nth-child(1){
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin: 0 auto;
    text-align: center;
}
.checkboxTimelineBigP p:nth-child(2){
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}


@media only screen and (min-width: 768px){
    .body-view-report-sm{
        padding: 0px 38%;
        /* box-shadow: ; */
        height: 100vh;
    }
    .container-body-report{
        box-shadow: none;
        height: 100%;
        box-shadow: -60px 0px 100px -90px #000000, 60px 0px 100px -90px #000000;
    }
}
.h2login{
    /* padding-left: 25px; */
    margin: 0px;
    /* font-family: 'Roboto', sans-serif; */
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    font-style: normal;
    background: linear-gradient(180deg, #F4E4BA 0%, #866328 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-login{
    background-color: #ffffff;
    border-radius: 20px;
    padding:30px 30px;
}

.box-middle{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.writeLogin{
    font-size: 22px;
    font-weight: 600;
}

.form-input{
    font-size: 16px;
    border-left: 7px solid #292d32;
    border-radius: 5px;
    padding: 12px 10px;
    box-shadow: 0 0 2px #292d32;
}

.form-input:focus {
    outline: none ;
    border:2px solid #C49E17;
    border-left: 7px solid #C49E17;
    box-shadow: 0 0 5px #C49E17;
}
.form-input:visited {
    outline: none ;
    border:2px solid #C49E17;
    border-left: 7px solid #C49E17;
    box-shadow: 0 0 5px #C49E17;
}
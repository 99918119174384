h4{
    color: #c49e17;
    font-weight: 500;
}
.modal-backdrop.in {
    opacity: 0.1 ;
}
.modal-content{
    box-shadow:0 5px 15px rgb(0 0 0 / 20%);
}

@media (min-width: 320px) and (max-width: 480px) {
    .modal-dialog{
        width: 90%;
    }
}
/* Mobile */
@media (min-width: 320px) and (max-width: 480px) {
    .fixed-div{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 50;
    }

    .fixed-div ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #1D1D1D;
    }
    .fixed-div li{
        float: left;
    }


    .menu{
        
        /* padding: 10px; */
        border-radius: 10px 10px 0px 0px;
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        grid-gap:10px;
        
    }
    .menu > li{
        padding: 15px;
        overflow-x: scroll;
    }
    .menu li > .menu-active-bottom{
        background-color: #C49E17;
    }
    .menu-active-bottom{
        color: #C49E17 !important;
    }
    .menu-active-bottom svg{
        /* padding: 10px; */
        border-radius: 3px;
        background-color: #C49E17 !important;
    }
    .menu-active-bottom svg path{
        fill: #ffffff;
    }
    .menu-active-bottom #logo-menu > svg {
        background-color: #C49E17;
        padding-top: 1px;
    }
}

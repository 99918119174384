@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:600&display=swap');
// @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css');
// @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js");


$black: #363839;
$lightgray: #9c9e9f;
$gray: #bdc1c6;
$white: #fff;
$green: #06842c;
$red: red;
$disabled1:#e0e2e4;
$bgSales: #1382c4;
$bgProduction: #ffa500;

input.checkbox[type="checkbox"]{
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}


input.checkbox[type="checkbox"] {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  width: 1.6em;
  height: 1.6em;
  color: $black;
  border: 2px solid $green;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  margin: 0px 0px;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 1px;
    left: 5px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: $white;
    border-color: $green;
    background: $green;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  &:disabled{
    // color: $white;
    border: $green 2px solid;
    background: $green;
    cursor: no-drop;
  }
}

input.checkboxStorage[type="checkbox"]{
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}


input.checkboxStorage[type="checkbox"] {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  width: 1.6em;
  height: 1.6em;
  color: $black;
  border: 2px solid $green;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  margin: 0px 0px;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 1px;
    left: 5px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: $white;
    border-color: $green;
    background: $green;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  &:disabled{
    // color: $white;
    border: $green 2px solid;
    background: $green;
    cursor: no-drop;
  }
}








input.checkboxSales[type="checkbox"]{
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}


input.checkboxSales[type="checkbox"] {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  width: 1.6em;
  height: 1.6em;
  color: $black;
  border: 2px solid $bgProduction;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  margin: 0px 0px;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 1px;
    left: 5px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: $white;
    border-color: $bgProduction;
    background: $bgProduction;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  &:disabled{
    // color: $white;
    border: $bgProduction 2px solid;
    background: $bgProduction;
    cursor: no-drop;
  }
}


input.checkboxProduction[type="checkbox"] {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  width: 1.6em;
  height: 1.6em;
  color: $black;
  border: 2px solid $bgSales;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  margin: 0px 0px;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 1px;
    left: 5px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: $white;
    border-color: $bgSales;
    background: $bgSales;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  &:disabled{
    // color: $white;
    border: $bgSales 2px solid;
    background: $bgSales;
    cursor: no-drop;
  }
}


$progress: #C49E17;
$onProgress: #449D25;




input.timelineProgressOn[type="checkbox"] {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  width: 30px;
  height: 30px;
  color: $black;
  border: 2px solid $onProgress;
  border-radius: 20px;
  appearance: none;
  outline: 0;
  cursor: default;
  margin: 0px 0px;
  background-color: $onProgress;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 3px;
    left: 8px;
    width: 10px;
    height: 16px;
    border-style: solid;
    border-color: $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 1;
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

input.timelineProgress[type="checkbox"] {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  width: 30px;
  height: 30px;
  color: $black;
  border: 2px solid $progress;
  border-radius: 20px;
  appearance: none;
  outline: 0;
  cursor: default;
  margin: 0px 0px;
  background-color: $progress;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 3px;
    left: 8px;
    width: 10px;
    height: 16px;
    border-style: solid;
    border-color: $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 1;
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

input.timelineSuccess[type="checkbox"] {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  width: 153px;
  height: 153px;
  color: $black;
  border: 2px solid $onProgress;
  border-radius: 100px;
  appearance: none;
  outline: 0;
  cursor: default;
  margin: 0px 0px;
  background-color: $onProgress;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 16px;
    left: 49px;
    width: 50px;
    height: 96px;
    border-style: solid;
    border-color: $white;
    border-width: 0 15px 15px 0;
    transform: rotate(45deg);
    opacity: 1;
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

// ORIGINAL
// #checklist {
//   --transparent: transparent;
//   --background: #ffffff;
//   --text: #414856;
//   --check: #c49e17;
//   --disabled: #C3C8DE;
//   // --width: 100px;
//   // --height: 140px;
//   --border-radius: 10px;
//   // background: var(--background);
//   width: var(--width);
//   height: var(--height);
//   border-radius: var(--border-radius);
//   position: relative;
//   // box-shadow: 0 10px 30px rgba(#414856, 0.05);
//   // padding: 30px 45px;
//   display: grid;
//   grid-template-columns: 30px auto;
//   align-items: center;
//   label {
//     color: var(--text);
//     position: relative;
//     cursor: pointer;
//     display: grid;
//     align-items: center;
//     width: fit-content;
//     transition: color .3s ease;
//     &::before,
//     &::after {
//       content:"";
//       position: absolute;
//     }
//     &::before {
//       height: 2px;
//       width: 8px;
//       left: -27px;
//       background: var(--check);
//       border-radius: 2px;
//       transition: background .3s ease; 
//     }
//     &:after {
//       height: 4px;
//       width: 4px;
//       top: 8px;
//       left: -25px;
//       border-radius: 50%;
//     }
//   }
//   input[type="checkbox"] {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     position: relative;
//     height: 15px;
//     width: 15px;
//     outline: none;
//     border: 0;
//     margin: 0 15px 0 0;
//     cursor: pointer;
//     background: var(--transparent);
//     display: grid;
//     align-items: center;

//     // tambahan
//     top:-2px;

//     &::before,
//     &::after {
//       content:"";
//       position: absolute;
//       height: 2px;
//       top: auto;
//       background: var(--check);
//       border-radius: 2px;
//     }
//     &::before {
//       width: 0px;
//       right: 60%;
//       transform-origin: right bottom;
//     }
//     &::after {
//       width: 0px;
//       left: 40%;
//       transform-origin: left bottom;
//     }
//     &:checked {
//       &::before {
//         animation: check-01 .4s ease forwards;
//       }
//       &::after {
//         animation: check-02 .4s ease forwards;
//       }
//       + label {
//         color: var(--disabled);
//         animation: move .3s ease .1s forwards;
//         &::before {
//           background: var(--disabled);
//           animation: slice .4s ease forwards;
//         }
//         &::after {
//           animation: firework .5s ease forwards .1s;
//         }
//       }
//     }
//   }
// }

// @keyframes move {
//   50% {
//     padding-left: 8px;
//     padding-right: 0px;
//   }
//   100% {
//     padding-right: 4px;
//   }
// }
// @keyframes slice {
//   60% {
//     width: 100%;
//     left: 4px;
//   }
//   100% {
//     width: 100%;
//     left: -2px;
//     padding-left: 0;
//   }
// }
// @keyframes check-01 {
//   0% {
//     width: 4px;
//     top: auto;
//     transform: rotate(0);
//   }
//   50% {
//     width: 0px;
//     top: auto;
//     transform: rotate(0);
//   }
//   51% {
//     width: 0px;
//     top: 8px;
//     transform: rotate(45deg);
//   }
//   100% {
//     width: 5px;
//     top: 8px;
//     transform: rotate(45deg);
//   }
// }
// @keyframes check-02 {
//   0% {
//     width: 4px;
//     top: auto;
//     transform: rotate(0);
//   }
//   50% {
//     width: 0px;
//     top: auto;
//     transform: rotate(0);
//   }
//   51% {
//     width: 0px;
//     top: 8px;
//     transform: rotate(-45deg);
//   }
//   100% {
//     width: 10px;
//     top: 8px;
//     transform: rotate(-45deg);
//   }
// }
// @keyframes firework {
//   0% {
//     opacity: 1;
//     box-shadow: 0 0 0 -2px #c49e17, 0 0 0 -2px #c49e17, 0 0 0 -2px #c49e17, 0 0 0 -2px #c49e17, 0 0 0 -2px #c49e17, 0 0 0 -2px #c49e17;
//   }
//   30% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//     box-shadow: 0 -15px 0 0px #c49e17, 14px -8px 0 0px #c49e17, 14px 8px 0 0px #c49e17, 0 15px 0 0px #c49e17, -14px 8px 0 0px #c49e17, -14px -8px 0 0px #c49e17;
//   }
// }
/* font 'Roboto' & 'Popins' */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700&display=swap');



body {
    height: 100%;
    margin: 0;
}
.body-padding{
    padding: 13px 2%;
}

.bgTools{
    background-color: #F7F7F8;
    height: 100%;
    padding: 0px;
    margin: 0px;
}
.lineBreakTd{
    line-break:anywhere;
    max-width: 100px;
}
.modal {
    text-align: center;
  }
  
  @media screen and (min-width: 768px) { 
    .modal:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }
  }
  
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

.w-100{width: 100%;}
.w-90{width: 90%;}
.w-80{width: 80%;}
.w-70{width: 70%;}
.w-60{width: 60%;}
.w-50{width: 50%;}

.h-100{height: 100%;}
.h-90{height: 90%;}
.h-80{height: 80%;}
.h-70{height: 70%;}
.h-60{height: 60%;}
.h-50{height: 50%;}



.fontFamily{font-family: 'Poppins', sans-serif;}
.fontFamily2{font-family: 'Roboto', sans-serif;}

.font-size10{font-size: 10px;}
.font-size11{font-size: 11px;}
.font-size12{font-size: 12px;}
.font-size14{font-size: 14px;}
.font-size16{font-size: 16px;}
.font-size26{font-size: 26px;}
.font-size25{font-size: 25px;}
.font-size28{font-size: 28px;}
.font-size30{font-size: 30px;}
.font-size36{font-size: 36px;}


.font-w100{font-weight: 100;}
.font-w200{font-weight: 200;}
.font-w300{font-weight: 300;}
.font-w400{font-weight: 400;}
.font-w500{font-weight: 500;}
.font-w600{font-weight: 600;}
.font-w700{font-weight: 700;}
.font-w800{font-weight: 800;}
.font-w900{font-weight: 900;}


.w-8vw{
    width: 8vw;
}

.color-1{
    color:#3E4142;
}
.color-2{
    color: #C49E17;
}

#menu li a:hover > span{
    color: #C49E17;
}
#menu ul li a:hover> svg path {
    background-color: #DFE6EA;
}

.float-right{
    float: right;
}
.float-left{
    float: left;
}

.box-shadow-1{
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
}
.border-radius-10{
    border-radius: 10px;
}
.border-radius-5{
    border-radius: 5px;
}

.mt-pto-1{
    margin: 10px 0px;
}
.pl-0{padding-left: 0px;}
.pl-5{padding-left: 5px;}
.pl-10{padding-left: 10px;}
.pl-15{padding-left: 15px;}
.pl-40{padding-left: 40px;}
.pr-0{padding-right: 0px;}
.pr-5{padding-right: 5px;}
.pr-15{padding-right: 15px;}
.pr-40{padding-right: 40px;}
.pt-0{padding-top: 0px;}
.pt-5{padding-top: 5px;}
.pt-10{padding-top: 10px;}
.pt-15{padding-top: 15px;}
.pt-20{padding-top: 20px;}
.pt-25{padding-top: 25px;}
.pt-35{padding-top: 35px;}
.pt-40{padding-top: 40px;}
.pt-50{padding-top: 50px;}
.pt-100{padding-top: 100px;}
.pb-0{padding-bottom: 0px;}
.pb-5{padding-bottom: 5px;}
.pb-10{padding-bottom: 10px;}
.pb-15{padding-bottom: 15px;}
.pb-20{padding-bottom: 20px;}
.pb-25{padding-bottom: 25px;}
.pb-35{padding-bottom: 35px;}
.pb-40{padding-bottom: 40px;}
.padding-v0{padding-left: 0%;padding-right: 0%;}
.padding-v10{padding-left: 10px; padding-right: 10px;}
.padding-v15{padding-left: 15px; padding-right: 15px;}
.padding-v18{padding-left: 18px; padding-right: 18px;}
.padding-h10{padding-top: 10px; padding-bottom: 10px;}
.padding-h20{padding-top: 20px; padding-bottom: 20px;}

.margin-h3{margin-top:3px; margin-bottom:3px;}
.margin-h10{margin-top:10px; margin-bottom:10px;}

.ml-10{margin-left: 10px;}
.ml-5{margin-left: 5px;}
.ml-15{margin-left: 15px;}
.ml-20{margin-left: 20px;}
.ml-25{margin-left: 25px;}
.ml-30{margin-left: 30px;}
.mr-5{margin-right: 5px;}
.mr-10{margin-right: 10px;}
.mr-15{margin-right: 15px;}
.mr-20{margin-right: 20px;}
.mr-25{margin-right: 25px;}
.mr-30{margin-right: 30px;}
.mt-10{margin-top: 10px;}
.mt-15{margin-top: 15px;}
.mt-20{margin-top: 20px;}
.mt-25{margin-top: 25px;}
.mt-30{margin-top: 30px;}
.mb-0{margin-bottom: 0px;}
.mb-5{margin-bottom: 5px;}
.mb-10{margin-bottom: 10px;}
.mb-15{margin-bottom: 15px;}
.mb-20{margin-bottom: 20px;}
.mb-25{margin-bottom: 25px;}
.mb-30{margin-bottom: 30px;}
.mb-40{margin-bottom: 40px;}
.mb-50{margin-bottom: 50px;}




.mrl-m-5{
    margin-left: 5px;
    margin-right: 5px;
}

.padding-h15{padding-top: 15px; padding-bottom: 15px;}

.border-left-1{border-left: 1px solid #1D1D1D;}
.border-left-2{border-left: 2px solid #1D1D1D;}
.border-left-3{border-left: 3px solid #1D1D1D;}
.border-left-20{border-left: 20px solid #1D1D1D;}

.mx-auto{margin-left:auto; margin-right: auto;}

.row-dflex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}
.row-dflex > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.d-flex{display: flex;}
.d-block{display: block;}
.d-grid{display: grid;}
.d-none{display: none!important;}

.button-typeText{
    height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #DFE6EA;
}
.button-typeText:focus{
    border: #06d45f solid 1px;
}
/* .button-typeText:valid{
    border: #06d45f solid 1px;
} */
.option-button{
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    color: #565656;
    padding: 8px 12px;
    height: 40px;
    box-shadow: none;
}

.div-admin > div{
    margin: 0px 1px;
    

}

/* position */
.position-relative{
    position:relative;
}
.position-absolute{
    position: absolute;
}
.position-fixed{
    position:fixed;
}
.position-inherit{
    position:inherit;
}

.scroll-x{
    overflow-x: scroll;
}
.scroll-x:before{
    overflow-x: scroll;
}


.button-typeText1{
    height: 40px;
    max-width: 120px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #DFE6EA;
}
.button-typeTextarea{
    /* height: 40px; */
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #DFE6EA;
    min-height: 60px;
}

.wrapper-table{
    overflow: scroll;
    
}

.borderBottom{
    border-bottom: 1px solid #565656;
}

.span-checklist{
    top:-6px;
    position: relative;
    margin-left: 5px;
}
/* header START */
    #header{
        background-color: #1d1d1d;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    }
    #header section img{
        width: 113px;
        height: auto;
    }
    #temp-header{
        display: flex;
    }
    #temp-header > div{
        display: flex;
    }
    #temp-header > div  div{
        margin: auto 0px;
    }
    #temp-header div  div >  p{
        padding-left: 25px;
        margin: 0px;
        /* font-family: 'Roboto', sans-serif; */
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        font-style: normal;
        background: linear-gradient(180deg, #F4E4BA 0%, #866328 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        /* text-fill-color: tr ansparent; */
    }
/* header END */

/* DASHBOARD */
    .dashboard-background-event{
        background: #1D1D1D;
        
    }
/* DASHBOARD */

/* SIDEBAR START */
    #sidebar-left h3{
        font-size: 18px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        color: #3E4142;
    }
    .menu-active{
        background-color: #1D1D1D;
        /* border-radius: 0px; */
        color: #C49E17 !important;
    }
    .menu-active span{
        font-style: normal;
        font-weight: 500;
    }
    .menu-active svg{
        /* padding: 10px; */
        border-radius: 3px;
        background-color: #C49E17 !important;
    }
    .menu-active svg path{
        fill: #ffffff;
    }
    .menu-active #logo-menu > svg {
        background-color: #C49E17;
        padding-top: 1px;
    }
    #menu > li a{
        padding: 10px;
    }
    #menu >li span{
        padding: 10px;
    }
    
    
/* SIDEBAR END */
#login{
    margin-top: 15vh;
}


/* BODY START */
    .min-width-td-status{
        min-width: 110px;
    }
    .span-breadcrumb{
        vertical-align: super;
        color: none;
        font-size: 14px;
        color:#979797;
    }
    #title-h1 h1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        color: #3E4142;
        margin-top: 0px;
    }
    /* #formUkuran2{
        padding: 2px;
        border: 1px solid #DFE6EA;
        border-radius: 4px;
        max-width: 120px;
    } */
    .xUkuran{
        padding: 8px 15px;
    }
    #konfigurasiUkuran{
        width: 12px;
        height: 12px;
        border: 1px solid #DFE6EA;
    }
    #uploadFoto{
        border: 1px solid #DFE6EA;
        border-radius: 4px;
    }

    .buttomSubmit{

        width: 85%;
        height: 38px;
        background: linear-gradient(89.59deg, #C49F18 57.28%, rgba(196, 159, 24, 0.6) 95.41%);
        border-radius: 4px;
        color:#ffffff;
        border: none;
    }
    .buttomSubmit:hover{
        background: #C49F18;   
    }

    .buttonSubmitKirim{
        width: 167px;
        height: 38px;
        left: 867px;
        top: 1073px;
        background: linear-gradient(89.59deg, #C49E17 57.28%, rgba(196, 159, 24, 0.6) 95.41%);
        border-radius: 4px;
        color:#ffffff;
        border: none;
        padding: 10px;
    }
    .buttonSubmitKirim:hover{
        background: #C49F18;
    }

    .fileHidden{
        display: none;
    }
    #teksBanner h3{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
    }
    .config{
        padding: 25px 10px;
    }
    .config1{
        padding: 50px 10px;
    }
    .config2{
        padding: 30px 25px;
    }

    .result-item-dashboard{
        background-color:  rgb(245 245 245 / 80%);
        color: #3E4142;
        border-radius: 10px;
        text-align: center;
        padding: 20px 10px;
        border-bottom-right-radius: 100px 100px;
        transition: 0.5s;
    }

    .dashboard-border-item{
        border: 2px solid #1d1d1d;
        border-radius: 15px;
        background-color: #1D1D1D;
        color: #ffffff;
        font-weight: 600;
    }

    .dashboard-border-item:hover, .dashboard-border-item:hover >div svg path{
        background-color: #1d1d1d;
        color:#C49E17;
        fill: #C49E17;
    }
    .dashboard-border-item:hover  .result-item-dashboard{
        background-color:#f5f5f5;
        border-bottom-right-radius:10px;
    }

    /* .fixed-div{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    } */
    
    

    /* .input{} */
/* BODY END */


/* RESPONSIVE */
/* TABLET */
    @media (min-width: 768px) and (max-width: 1024px) {
        #temp-header{
            display: flex;
            justify-content: center;
        }
        #header section img{
            width: 100px;
            height: auto;
        }
        #temp-header div  div >  p{
            padding-left: 25px;
            margin: 0px;
            /* font-family: 'Roboto', sans-serif; */
            font-weight: 500;
            font-size: 30px;
            line-height: 35px;
            font-style: normal;
            background: linear-gradient(180deg, #F4E4BA 0%, #866328 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            /* text-fill-color: transparent; */
        }
    }
/* TABLET */

/* SmarthPhone */
@media (min-width: 320px) and (max-width: 480px) {
    .mb-17per{
        margin-bottom: 17%;
    }

    .min-width-td-status{
        min-width: 110px;
    }
    .first-col {
        width: 100px;
        max-width: 100px;
        left: 0px; 
        white-space:pre-line;
    } 
    

    .mrl-m-5{
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    #main-body{
        padding: 0px;
    }
    #temp-header{
        display: flex;
        justify-content: center;
    }
    #header section img{
        width: 50px;
        height: auto;
    }
    #temp-header div div >  p{
        padding-left: 25px;
        margin: 0px;
        /* font-family: 'Roboto', sans-serif; */
        font-weight: 500;
        font-size: 18px;
        line-height: 35px;
        font-style: normal;
        background: linear-gradient(180deg, #F4E4BA 0%, #866328 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        /* text-fill-color: transparent; */
    }
    .pl-40{padding-left: 0px;}

    .m-plr-15{
        padding: 0 15px !important;   
    }
}
/* SmarthPhone */




/* -- Design CSS By Blog Xkomo.com --*/
ul,
ul li,
ul li ul {
    list-style: none outside none;
    padding:0;
    margin:0;
}
#menu-wrap {
    text-decoration: none;
    color:#3E4142;
}
#menu {
    padding: 18px 0;
}
#menu > li > a {
    color:#3E4142;
    display: flex;
    margin: 0 auto;
    /* width: 90%; */
    /* text-indent: 18px; */
    /* line-height: 39px; */
    text-decoration: none;
}

.tr-uncheck{
    background-color: #ffd050 !important;
}
.td-uncheck{
    background-color: transparent !important;
}
#menu > li > a.tutup::after {
    position: absolute;
    right: 30px;
    margin-top: 2px;
    font-family: "FontAwesome";
    content: "f054";
}
#menu > li > a.tutup:hover{
    color:#3E4142;
    font-weight: bold;
    border-radius:5px;
}

#menu > li > a:hover{
color: #FFF;
font-weight: bold;
    /* border-radius:5px; */
}
#menu > li > a.tutup:hover, 
#menu > li > a.tutup.buka {
    color: #fff;
}
#menu > li > a.tutup.buka::after {
    content: "&#xf107;";
}
.sub {
    display: none;
}
.sub a:hover{
    border-radius:5px;
    font-weight:bold;
}
.sub a {position: relative;
display: block;
margin: 0 auto;
/* width: 212px; */
text-indent: 0px;
line-height: 39px;
padding-left: 20px;
color: rgb(196, 196, 196);
text-decoration: none;
}
.sub a:before {
font-style: normal;
font-weight: normal;
text-decoration: inherit;
color: rgb(0, 40, 83);
font-size: 18px;
padding-right: 0.5em;
position: absolute;
top: 2px;
left: 7px;
}


/* table */
/* .view-table {
    margin: auto;
}
  
.wrapper-table{
position: relative;
overflow: auto;

white-space: nowrap;
}

.sticky-col {
    position: sticky;
    position: -webkit-sticky;    
    background-color: white;
}

.first-col {
    width: 100px;
   
    max-width: 100px;
    left: 0px; 
    white-space:pre-line;
} */



/* table */

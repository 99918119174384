h3{
    font-size: 17px;
}
.bg-jumlah{
    background-color: #bdbdbd ;
    /* padding: 5px 10px; */
    padding: 15px;
    border-radius: 5px;
}
.bg-inputTextarea{

}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
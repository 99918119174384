@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700&display=swap');
.fontFamily{font-family: 'Poppins', sans-serif;}
.fontFamily2{font-family: 'Roboto', sans-serif;}

.font-w100{font-weight: 100;}
.font-w200{font-weight: 200;}
.font-w300{font-weight: 300;}
.font-w400{font-weight: 400;}
.font-w500{font-weight: 500;}
.font-w600{font-weight: 600;}
.font-w700{font-weight: 700;}
.font-w800{font-weight: 800;}
.font-w900{font-weight: 900;}

.pl-0{padding-left: 0px;}
.pl-10{padding-left: 10px;}
.pl-15{padding-left: 15px;}
.pl-20{padding-left: 20px;}
.pl-25{padding-left: 25px;}
.pl-35{padding-left: 35px;}
.pl-40{padding-left: 40px;}
.pr-0{padding-right: 0px;}
.pr-10{padding-right: 10px;}
.pr-15{padding-right: 15px;}
.pr-20{padding-right: 20px;}
.pr-25{padding-right: 25px;}
.pr-30{padding-right: 30px;}
.pr-35{padding-right: 35px;}
.pr-40{padding-right: 40px;}
.pt-0{padding-top: 0px;}
.pt-10{padding-top: 10px;}
.pt-15{padding-top: 15px;}
.pt-20{padding-top: 20px;}
.pt-25{padding-top: 25px;}
.pt-35{padding-top: 35px;}
.pt-40{padding-top: 40px;}
.pb-10{padding-bottom: 10px;}
.pb-15{padding-bottom: 15px;}
.pb-20{padding-bottom: 20px;}
.pb-25{padding-bottom: 25px;}
.pb-35{padding-bottom: 35px;}
.pb-40{padding-bottom: 40px;}
.padding-v0{padding-left: 0%;padding-right: 0%;}
.padding-v10{padding-left: 10px; padding-right: 10px;}
.padding-v15{padding-left: 15px; padding-right: 15px;}
.padding-v18{padding-left: 18px; padding-right: 18px;}
.padding-h10{padding-top: 10px; padding-bottom: 10px;}
.padding-h20{padding-top: 20px; padding-bottom: 20px;}

.margin-h3{margin-top:3px; margin-bottom:3px;}
.margin-h10{margin-top:10px; margin-bottom:10px;}
.ml-10{margin-left: 10px;}
.ml-15{margin-left: 15px;}
.ml-20{margin-left: 20px;}
.ml-25{margin-left: 25px;}
.ml-30{margin-left: 30px;}
.mt-10{margin-top: 10px;}
.mt-15{margin-top: 15px;}
.mt-20{margin-top: 20px;}
.mt-25{margin-top: 25px;}
.mt-30{margin-top: 30px;}
.mb-10{margin-bottom: 10px;}
.mb-15{margin-bottom: 15px;}
.mb-20{margin-bottom: 20px;}
.mb-25{margin-bottom: 25px;}
.mb-30{margin-bottom: 30px;}


.mx-auto{margin:0 auto!important;}

/* Percent */
.mt-0prc{margin-top: 0%;}
.mt-5prc{margin-top: 5%;}
.mt-10prc{margin-top: 10%;}
.mt-15prc{margin-top: 15%;}
.mt-20prc{margin-top: 20%;}
.mt-25prc{margin-top: 25%;}

/* .active{
    background-color: blue;
} */
.table-hover>tbody>tr:hover {
    background-color: #dedede;
}
.table-striped > tbody > tr:nth-child(2n+1):hover > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
    background-color: #dedede;
 }
.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
    /* background-color: #eeeeee; */
 }

 .table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #eeeeee;
}

.table-hover>tbody>tr:hover {
    background-color: #dedede !important;
}

 .badge-plug{
    padding: 7px 9px;
    background-color: #b39229;
    color: bisque;
    border-radius: 100%;
    margin-right: 10px;
 }


 .buttonTambah{
    background:#C49E17;
    border-radius: 4px;
    color:#ffffff;
    border: none;
    padding: 10px;
}
.buttonTambah:hover{
    background: #C49F18;
    color: #f7f7f8;
}

#previewFoto{
    padding: 10px;
}
#previewFoto img{
    padding: 10px;
}



.namaToko{
    color:#c49e17;
    /* background: linear-gradient(180deg, #F4E4BA 100%, #866328 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; */
}
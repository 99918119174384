.print-{
    /* width: 788px; */
    padding: 15px 15px 40px 15px;
    border: 2px solid #000;
    margin: 10px
}
.dotted{
    border: 2px dotted #222222; 
    border-style: none none dashed; 
    color: #fff; 
    background-color: #fff; 
}

.print-iconCut{
    right: -7px;
    position: absolute;
    margin-top: -26px;
}
